<template>
  <div class="the-shamrock-background">
    <div class="wrapper flex flex-col w-full h-full mx-auto">
      <Illustration
        v-for="(_, index) in new Array(count)"
        v-bind="{ height, width, name }"
        :key="`background-image-${index}`"
        alt=""
        aria-hidden="true"
        :class="[shamrockClass, 'shamrock', index % 2 === 0 ? 'right' : 'left']"
        data-testid="shamrock-background"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
type TheShamrockBackgroundProps = {
  count?: number
  height?: number
  horizontalMargin?: string
  shamrockClass?: string
  type?: 'white'
  width?: number
}

const props = withDefaults(defineProps<TheShamrockBackgroundProps>(), {
  count: 4,
  height: 320,
  horizontalMargin: '-9.75rem',
  width: 320,
})

const name = computed(() => `shamrock${props.type ? `-${props.type}` : ''}`)
</script>

<style lang="scss" scoped>
.the-shamrock-background {
  z-index: -1;
  overflow: hidden;

  &,
  &::after {
    position: absolute;
    inset: 0;
  }

  &::after {
    content: '';
  }

  .wrapper {
    max-width: $main-content-width;

    .shamrock {
      &.left {
        align-self: flex-start;
        margin-left: v-bind(horizontalMargin);
      }

      &.right {
        align-self: flex-end;
        margin-right: v-bind(horizontalMargin);
      }
    }
  }
}
</style>
